import React from "react"
import Link from 'gatsby-link'

import Layout from "../components/layout"
import SEO from "../components/seo"

const Content = ({ children }) => (
    <p className="is-size-4-desktop is-size-5-mobile is-size-5-tablet">{children}</p>
)

const LearnMorePage = () => (
  <Layout>
    <SEO title="learn more" />

    <div className="content mt-6">
      <Content>upgrade your pieces to rare sought-after collectors items</Content>

      <table className="table is-transparent is-fullwidth mt-6">
        <thead>
          <tr>
            <th>Action</th>
            <th>Reward</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Collect your first depicted poster</td>
            <td>1 x free A4-sized physical print of your poster (shipping included).</td>
          </tr>
          <tr>
            <td>Collect any 5 words</td>
            <td>A free sixth word of your choosing.</td>
          </tr>
          <tr>
            <td>Collect a perfectly-drawn yellow banana</td>
            <td>A <span className="yellow-text">animated golden</span> poster title.</td>
          </tr>
          <tr>
            <td>Find a pair of <Link to="/diamond/">diamond</Link> holding hands 💎🤲.</td>
            <td>An special-edition rocket 🚀 poster (unavailable to purchase).</td>
          </tr>
        </tbody>
      </table>
    </div>
  </Layout>
)

export default LearnMorePage